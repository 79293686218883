<template>
  <div class="containers">
    <div class="title">劳动合同</div>
    <div class="doms inputBox">
      <div class="inputs">
        甲方：{{query.company_name}}
      </div>
      <div class="inputs">
        <span style="flex-shrink: 0">乙方：</span>
        <van-field class="inputsItem" v-model="query.name" />
      </div>
      <div class="inputs">
        <span style="flex-shrink: 0">甲方（用人单位）：</span>
        {{query.company_name}}
      </div>
      <div class="inputs">
        <span style="flex-shrink: 0">法定代表人（或主要负责人）：</span>
        {{query.company_person}}
      </div>
      <div class="inputs">
        <span style="flex-shrink: 0">住所：</span>
        {{query.company_address}}
      </div>
      <div class="inputs">
        <span style="flex-shrink: 0">乙方（劳动者）姓名：</span>
        <van-field class="inputsItem" v-model="query.name" />
      </div>
      <div class="inputs">
        <span style="flex-shrink: 0">身份证编号：</span>
        {{query.ID_number}}
      </div>
      <div class="inputs">
        <span style="flex-shrink: 0">住址：</span>
        <van-field class="inputsItem" v-model="query.address"/>
      </div>
      <div class="itemText">根据《中华人民共和国劳动法》、《中华人民共和国劳动合同法》等有关法律法规，遵循合法、公平、平等自愿、协商一致、诚实信用的原则，甲、乙双方订立本劳动合同，共同信守本合同所列各项条款。</div>
      <div class="itemTitle">一、本合同期限选用</div>
      <div class="contract-term selfStyle">
        <span class="textIndent">1.固定期限：</span>
        共
        <van-field class="inputs input50" v-model="query.year" style="width: 60px;" />
        年，自
        <span class="date-placeholder">
        <van-field class="inputs input50" v-model="query.sy" style="width: 60px;" />
        </span>年
        <span class="date-placeholder">
        <van-field class="inputs input50" v-model="query.sm" style="width: 60px;" />
        </span>
        月
        <span class="date-placeholder">
        <van-field class="inputs input50" v-model="query.sd" style="width: 60px;" />
        </span>日
        起至
        <span class="date-placeholder">
          <van-field class="inputs input50" v-model="query.ey" style="width: 60px;" />
        </span>年
        <span class="date-placeholder">
          <van-field class="inputs input50" v-model="query.em" style="width: 60px;" />
        </span>月
        <span class="date-placeholder">
         <van-field class="inputs input50" v-model="query.ed" style="width: 60px;" />
        </span>日止，其中试用期为
        <span class="trial-period">
        <van-field class="inputs input50" v-model="query.sy_m" style="width: 60px;" />
        </span>个月，自
        <span class="date-placeholder">
         <van-field class="inputs input50" v-model="query.sy_sy" style="width: 60px;" />
        </span>年
        <span class="date-placeholder">
          <van-field class="inputs input50" v-model="query.sy_sm" style="width: 60px;" />
        </span>月
        <span class="date-placeholder">
        <van-field class="inputs input50" v-model="query.sy_sd" style="width: 60px;" />
        </span>日
        起至
        <span class="date-placeholder">
          <van-field class="inputs input50" v-model="query.sy_ey" style="width: 60px;" />
        </span>年
        <span class="date-placeholder">
         <van-field class="inputs input50" v-model="query.sy_em" style="width: 60px;" />
        </span>月
        <span class="date-placeholder">
         <van-field class="inputs input50" v-model="query.sy_ed" style="width: 60px;" />
        </span>日止。
      </div>
      <div class="textIndent">2.以完成某项任务为期限：本合同自</div>
      <div class="contract-term selfStyle">
              <span class="date-placeholder">
         <van-field class="inputs input50" disabled style="width: 60px;" />
         </span> 年
        <span class="date-placeholder">
         <van-field class="inputs input50" disabled style="width: 60px;" />
         </span> 月
        <span class="date-placeholder">
         <van-field class="inputs input50" disabled style="width: 60px;" />
         </span> 日起生效至工作任务完成之日终止。
        <div class="textIndent">3.无固定期限：本合同自</div>
        <span class="date-placeholder">
         <van-field class="inputs input50" disabled style="width: 60px;" />
         </span> 年
        <span class="date-placeholder">
         <van-field class="inputs input50" disabled style="width: 60px;" />
         </span> 月
        <span class="date-placeholder">
         <van-field class="inputs input50" disabled style="width: 60px;" />
         </span> 日起生效。
      </div>
      <div class="itemTitle">二、工作内容和工作地点</div>
      <div class="contract-term selfStyle">
        <span class="textIndent">
          1、乙方同意根据甲方的生产工作需要，在
        </span>
        <van-field disabled class="inputs" />
              地区担任
        <van-field disabled class="inputs" />
        岗位（工种）工作。并按甲方关于本岗位（工种）的要求完成规定的数量、质量指标或工作任务。
        <div class="textIndent">
          2、在合同期内因生产、经营工作需要，甲方与乙方协商同意，可以调整乙方的工作地点或工作岗位（工种），或指派乙方从事其他临时性、特殊性工作。
        </div>
      </div>
      <div class="itemTitle">三、工作时间和休息</div>
      <div class="contract-term selfStyle">
        <span class="textIndent">
          1.甲方安排乙方执行以下第
        </span>
        <span class="date-placeholder">
         <van-field disabled class="inputs input50" style="width: 60px;" />
         </span>
        种方式工时制度。
        <div class="textIndent">
          (1)执行标准工时工作制的，乙方每日工作时间不超过八小时，每周不超过四十小时。
        </div>
        <div class="textIndent">
          (2)执行综合计算工时工作制的，在工作周期内，平均日工作时间不得超过八小时，平均周工作时间不得超过四十小时。
        </div>
        <div class="textIndent">
          (3)执行不定时工作制的，在保障职工身体健康并充分听取职工意见的基础上，采用集中工作、集中休息、调休、轮休弹性工作时间等适当方式，确保职工的休息休假权利和生产、工作任务的完成。
        </div>
        <div class="textIndent">
          执行综合计算工时工作制和不定时工作制的，需要到劳动行政部门办理审批手续，经批准后方可执行。
        </div>
        <div class="textIndent">
          2、甲方因生产经营需要，征得单位工会和乙方同意后，可安排乙方加班加点，但不得违反国家法律法规的规定。
        </div>
        <div class="textIndent">
          3、乙方在合同期内享受国家规定的各项休息、休假的权利。
        </div>
      </div>
      <div class="itemTitle">四、劳动报酬和社会保险</div>
      <div class="contract-term selfStyle">
          <span class="textIndent">1.甲方以货币形式按月向乙方支付工资，不得以实物或有价证券代替货币支付，不得无故拖欠和克扣劳动者工资，为乙方按月向社会保险机构缴纳社会保险费。</span>
          <span class="textIndent">2.经甲乙双方协商一致，对乙方的工资报酬选择按第     </span>
          <span class="date-placeholder">
           <van-field disabled class="inputs input50" v-model="query.create_year" style="width: 60px;" />
           </span>方式执行
      </div>
      <div class="contract-term selfStyle">
        <div class="textIndent">（1）.乙方的工资报酬按照甲方依法制定的规章制度中的内部工资分配办法确定，根据乙方的工作岗位确定其每月工资为</div>
         <van-field disabled class="inputs"  />元
        <div class="textIndent">（2）.甲方对乙方实行基本工资和绩效工资相结合的内部工资分配办法，乙方的基本工资确定为每月</div>
        <van-field disabled class="inputs"  />
        <span>元，以后根据内部工资分配办法调整其工资；绩效工资根据乙方的工作业绩、劳动成果和实际贡献按照内部分配办法考核确定。</span>
        <div class="textIndent">
          （3）甲方实行计件工资制，确定乙方的劳动定额应当是本单位绝大多数同岗位（工种）劳动者在法定工作时间内能够完成的，乙方在法定工作时间内按质完成甲方定额，甲方按照约定的定额和计件单价，根据乙方的业绩，按时足额支付乙方的工资报酬。
        </div>
        <div class="textIndent">
          （4）其他
        </div>
        <van-field disabled class="inputs"  />
        <div class="textIndent">
          3、甲方支付给乙方提供正常劳动的工资不得低于当地最低工资标准，按有关规定支付给乙方加班加点工资。
        </div>
      </div>
      <div class="itemTitle">五、劳动保护、劳动条件和职业危害防护</div>
      <div class="contract-term selfStyle">
        <div class="textIndent">
          1、甲方应建立健全各项规章制度，对乙方进行政治思想、职业道德、业务技术、劳动安全卫生及有关规章制度的教育和培训。
        </div>
        <div class="textIndent">
          2、甲方应严格执行国家和地方有关劳动保护的法律、法规和规章，为乙方提供符合国家规定的劳动安全卫生条件和必要的劳动保护用品，提供保障乙方安全、健康的工作环境，建立健全生产工艺流程、制定操作规程、工作规范和劳动安全卫生制度及其标准。
        </div>
        <div class="textIndent">
          3、对乙方从事接触职业病危害作业的，甲方应按国家有关规定组织上岗前和离岗时的职业健康检查，在合同期内应定期对乙方进行职业健康检查。
        </div>
        <div class="textIndent">
          4、有下列情形之一的，乙方有权拒绝接受甲方安排的生产、经营和工作任务：
        </div>
        <div class="textIndent">
          (1)经国家有关部门确认，劳动安全、卫生条件恶劣，严重损害职工身体健康的；
        </div>
        <div class="textIndent">
          (2)违反国家法律法规，侵害国家、集体和个人利益的；
        </div>
        <div class="textIndent">
          (3)违反操作规程的。
        </div>
      </div>
      <div class="itemTitle">六、劳动合同解除的条件</div>
      <div class="contract-term selfStyle">
        <div class="textIndent">
          1、甲、乙双方协商一致，劳动合同可以提前解除。
        </div>
        <div class="textIndent">
          2、甲方有下列情形之一的，乙方可以解除劳动合同：
        </div>
        <div class="textIndent">
          (1)未按照劳动合同约定提供劳动保护或者劳动条件的；
        </div>
        <div class="textIndent">
          (2)未及时足额支付劳动报酬的；
        </div>
        <div class="textIndent">
          (3)未依法为乙方缴纳社会保险费的；
        </div>
        <div class="textIndent">
          (4)甲方的规章制度违反法律、法规的规定，损害乙方权益的；
        </div>
        <div class="textIndent">
          (5)因甲方以欺诈、胁迫的手段或者乘人之危，使乙方在违背真实意思的情况下订立或者变更劳动合同，或甲方免除自己的法定责任、排除乙方权利，以及劳动合同内容违反法律、行政法规强制性规定，致使劳动合同无效的；
        </div>
        <div class="textIndent">
          (6)法律、行政法规规定乙方可以解除劳动合同的其他情形。
          甲方以暴力、威胁或者非法限制人身自由的手段强迫乙方劳动的，或者甲方违章指挥、强令冒险作业危及乙方人身安全的，乙方可以立即解除劳动合同，不需事先告知甲方。
        </div>
        <div class="textIndent">
          3、乙方有下列情形之一的，甲方可以解除劳动合同：
        </div>


        <div class="textIndent">
          (1)在试用期间被证明不符合录用条件的；
        </div>
        <div class="textIndent">
          (2)严重违反甲方的规章制度的；
        </div>
        <div class="textIndent">
          (3)严重失职，营私舞弊，给甲方造成重大损害的；
        </div>
        <div class="textIndent">
          (4)乙方同时与其他用人单位建立劳动关系，对完成本单位的工作任务造成严重影响，或者经甲方提出，拒不改正的；
        </div>
        <div class="textIndent">
          (5)以欺诈、胁迫的手段或者乘人之危，使甲方在违背真实意思的情况下订立或者变更劳动合同，致使劳动合同无效的；
        </div>
        <div class="textIndent">
          (6)被依法追究刑事责任的。
        </div>
        <div class="textIndent">
          4、有下列情形之一的，甲方提前三十日以书面形式通知乙方或者额外支付乙方一个月工资后，可以解除劳动合同： 
        </div>
        <div class="textIndent">
          (1)乙方患病或者非因工负伤，在规定的医疗期满后不能从事原工作，也不能从事由甲方另行安排的工作的；
        </div>
        <div class="textIndent">
          (2)乙方不能胜任工作，经过培训或者调整工作岗位，仍不能胜任工作的；
        </div>
        <div class="textIndent">
          (3)劳动合同订立时所依据的客观情况发生重大变化，致使劳动合同无法履行，经甲方与乙方协商，未能就变更劳动合同内容达成协议的。
        </div>
        <div class="textIndent">
          5、乙方有下列情形之一的，甲方不得解除劳动合同：
        </div>
        <div class="textIndent">
          (1)乙方从事接触职业病危害作业未进行离岗前职业健康检查，或者疑似职业病病人在诊断或者医学观察期间的；
        </div>
        <div class="textIndent">
          (2)在本单位患职业病或者因工负伤并被确认丧失或者部分丧失劳动能力的；
        </div>
        <div class="textIndent">
          (3)患病或者非因工负伤，在规定的医疗期内的；
        </div>
        <div class="textIndent">
          (4)女职工在孕期、产期、哺乳期的；
        </div>
        <div class="textIndent">
          (5)在本单位连续工作满十五年，且距法定退休年龄不足五年的；
        </div>
        <div class="textIndent">
          (6)法律、行政法规规定的其他情形。
        </div>
      </div>
      <div class="itemTitle">七、甲方应当在解除或者终止劳动合同时出具解除或者终止劳动合同的证明，并在十五日内为乙方办理档案和社会保险关系转移手续。乙方应当按照双方约定，办理工作交接。</div>
      <div class="itemTitle">八、在劳动合同履行过程中，甲、乙方发生劳动争议，可申请本单位劳动争议调解委员会调解，或按规定直接向有管辖权的劳动争议仲裁委员会申请仲裁，对仲裁裁决不服的，可在规定期限内向当地人民法院起诉。</div>
      <div class="itemTitle">九、本合同未尽事宜，凡国家、省有规定的，均按国家和省里的规定执行；凡属国家、省没有规定的，甲、乙双方可以补充条款。</div>
      <div class="itemTitle">十、甲、乙双方商定必须遵守和执行的其他事项，载入附录或作为合同附件。</div>
      <div class="itemTitle">十一、劳动合同签订后，应及时到甲方所在地劳动保障行政部门办理用工备案。</div>
      <div class="itemTitle">十二、本合同一式两份，甲、乙双方各执一份。</div>
      <div class="itemTitle contract-term selfStyle">十三、本合同自
        <span class="date-placeholder">
        <van-field disabled class="inputs input50" style="width: 60px;" />
        </span>年
        <span class="date-placeholder">
        <van-field disabled class="inputs input50" style="width: 60px;" />
        </span>月
        <span class="date-placeholder">
        <van-field disabled class="inputs input50" style="width: 60px;" />
        </span>日起生效。
      </div>
      <div class="itemTitle">甲方：（盖章）</div>
      <img v-if="query.seal_path" class="hetong" :src="query.seal_path" alt="">

      <div class="itemTitle">法定代表人：（主要负责人）（盖章）</div>
      <div class="itemTitle">
        乙方：（签名或盖章）
        <div class="sign">
          <vue-esign v-if="!query.sign_file" id="sign"  class="card" ref="esign" :height="350" :isCrop="false" :lineWidth="10" :bgColor.sync="bgColor" :lineColor="'#000'" />
          <img v-else class="card" :src="query.sign_file" alt="">
          <div class="reset" @click="resetSign">重新签名</div>
        </div>
      </div>
      <div class="itemTitle contract-term selfStyle">
        签订本劳动合同时间：
        <span class="date-placeholder">
        <van-field class="inputs input50" v-model="query.select_year" style="width: 60px;" />
        </span>年
        <span class="date-placeholder">
        <van-field class="inputs input50" v-model="query.select_month" style="width: 60px;" />
        </span>月
        <span class="date-placeholder">
        <van-field class="inputs input50" v-model="query.select_day" style="width: 60px;" />
        </span>日
      </div>
      <div class="itemTitle">备案机关：（盖章）</div>
      <div class="itemTitle">备案人：（盖章）</div>
      <div class="itemTitle contract-term selfStyle">
        备案本劳动合同时间：
        <span class="date-placeholder">
        <van-field disabled class="inputs input50" v-model="query.create_year" style="width: 60px;" />
        </span>年
        <span class="date-placeholder">
        <van-field disabled class="inputs input50" v-model="query.create_year" style="width: 60px;" />
        </span>月
        <span class="date-placeholder">
        <van-field disabled class="inputs input50" v-model="query.create_year" style="width: 60px;" />
        </span>日
      </div>

      <div class="footerBar">
        <div class="submitBtn" @click="submitInfo">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import {SaveContract, LoadContract, SaveRenewalContract, uploadFile, LoadRenewalContract} from "@/api/paper";

export default {
  data() {
    return {
      query: {},
      bgColor: '',
    }
  },
  created() {
    this.loadData()

  },
  methods: {
    resetSign() {
      if(this.query.sign_file) {
        this.query.sign_file = ''
      } else {
        this.$refs.esign.reset()
      }
    },
    loadData() {
      let url = this.$route.query.id == 6 ? LoadRenewalContract : LoadContract
      url({openId: this.$store.state.info.openId}).then(res => {
        if(res.row && res.row.name) {
          this.query = res.row
        }
      })
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    // 将blob转换为file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return theBlob
    },
    async drawImg() {
      this.$refs.esign.generate().then(res => {
        var blob = this.dataURLtoBlob(res)
        var tofile = this.blobToFile(blob, '签名.png')
        setTimeout(async () => {
          const formData = new FormData()
          formData.append('file', tofile, tofile.name)
          // ajax 请求
          uploadFile(formData).then(res => {
            this.query.sign_file = res.filePath
            this.query.sign_pic_name = res.picName
            this.submit()
          })
        })
      })
    },
    submit() {
      let url = this.$route.query.id  == 6 ? SaveRenewalContract : SaveContract
      this.query.openId = this.$store.state.info.openId
      url(this.query).then(res => {
          if(res.result) {
            this.$notify({
              type: "success",
              message: "提交成功",
            });
            this.$router.go(-1)
          } else {
            this.$notify({
              type: "warning",
              message: res.msg,
            });
          }
        })
    },
    submitInfo() {
      if(!this.query.name) {
        return  this.$notify({
          type: "warning",
          message: "请输入乙方名称",
        });
      }
      if(!this.query.address) {
        return  this.$notify({
          type: "warning",
          message: "请输入住址",
        });
      }
      if(!this.query.year || !this.query.sy || !this.query.sm || !this.query.sd || !this.query.ey || !this.query.em || !this.query.ed || !this.query.sy_m || !this.query.sy_sy || !this.query.sy_sm || !this.query.sy_sd || !this.query.sy_ey || !this.query.sy_em || !this.query.sy_ed) {
        return  this.$notify({
          type: "warning",
          message: "请将第一条中的合同固定期限填写完整",
        });
      }
      if(!this.query.select_year || !this.query.select_month || !this.query.select_day) {
        return  this.$notify({
          type: "warning",
          message: "请将签订本劳动合同时间填写完整",
        });
      }
      if(!this.query.sign_file && !this.$refs.esign.hasDrew ) {
        return this.$notify({
          type: "warning",
          message: "请签名~",
        });
      }
      if(this.query.sign_file) {
        this.submit()
      } else {
        this.drawImg()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hetong {
  width: 180px;
  height: 180px;
}
  .containers {
    box-sizing: border-box;
    padding: 28px;
    font-family: PingFang SC;
    font-size: 12px;
    color: #777777;
    line-height: 16px;
  }
  .inputs {
    display: flex;
    align-items: center;
    .inputsItem {
      font-size: 12px;
      border-bottom: 1px solid #DDDDDD;
      text-align: center !important;
      padding: 0 !important;
    }
  }
  .footerBar {
    text-align: right;
  }
  .submitBtn {
    background: #02DBCA;
    border-radius: 20px;
    color: #FFFFFF;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 60px 0;
    color: #000000;
  }
.inputBox {
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  color: #666666;
  line-height: 20px;
  .itemTitle {
    font-weight: bold;
  }
}
.inputs {
  font-size: 12px;
  border-bottom: 1px solid #DDDDDD;
  text-align: center !important;
  padding: 0 !important;
}
.input50 {
  width: 45px !important;
}
.input130 {
  width: 130px;
}
.input80 {
  width: 80px;
}
.textIndent {
  text-indent: 2em;
}
.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.contract-term {
  display: flex;
  align-items: center; /* 垂直居中 */
  flex-wrap: wrap; /* 允许内容自动换行 */
}
.sign {
  flex-wrap: wrap;
  .signLabel {
    flex-shrink: 0;
    font-family: PingFang SC;
    font-size: 15px;
    color: #666666;
  }
  .card {
    width: 100%;
    height: 70px;
    background: #F7F8FA;
    border: 1px solid #DDDDDD;
  }
}
.reset {
  width: 200rpx;
  display: inline-block;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #02DBCA;
  color: #02DBCA;
  border-radius: 10px;
  margin-top: 15px;
}
</style>
<style>
.selfStyle .van-field__control {
  text-align: center !important;
}
</style>
